"use client";
import LoadingLink from "@/components/LoadingLink/LoadingLink";
import { useEffect, useState } from "react";

export default function NotFoundMain() {
  const [ isLoaded, setIsLoaded ] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div className={`container ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'}`}>
      <div className="flex flex-col-reverse lg:flex-row lg:justify-stretch">

        <div className="flex items-center justify-center lg:justify-start w-full lg:w-1/2">
          <div className="relative text-center lg:text-start">
            <div className="text-[96px] md:text-[120px] lg:text-[145px] leading-none text-honolulu-blue font-medium mb-10">404</div>
            <div className="text-2xl font-medium mb-3">Üzgünüz, aradığınız sayfayı bulamadık.</div>
            <div className="mb-6">
              Sayfa adresi yanlış yazılmış veya yayından kaldırılmış olabilir.
            </div>
            <LoadingLink href="/" className="btn btn-blue !inline-flex justify-center items-center" title="Doping Hafıza">Anasayfaya Git</LoadingLink>
          </div>
        </div>

        <div className="w-full lg:w-1/2 pb-7 ps-4 lg:pb-0 lg:px-3">
          <svg viewBox="0 0 481 401" fill="none" className="w-full max-w-[320px] mx-auto lg:max-w-full" xmlns="http://www.w3.org/2000/svg">
            <path d="M462.852 333.922C462.852 333.922 416.072 360.403 383.791 365.7C351.51 370.996 331.676 365.971 331.676 365.971L243.861 312.466C244.134 312.466 314.031 319.256 377.636 289.108L462.852 333.922Z" fill="#A0D6FF"></path>
            <path d="M480.913 315.861C480.913 315.861 434.133 342.342 401.852 347.638C369.571 352.934 349.737 347.91 349.737 347.91L261.922 294.405C262.195 294.405 332.092 301.195 395.697 271.047L480.913 315.861Z" fill="#0B6AB2"></path>
            <path d="M191.935 46.8343C191.935 46.8343 161.334 64.491 140.18 68.2839C118.892 72.0769 105.853 68.8071 105.853 68.8071L47.4458 34.6707C47.4458 34.6707 93.6131 38.4637 135.257 18.1911L191.935 46.8343Z" fill="#A0D6FF"></path>
            <path d="M203.223 35.4878C203.223 35.4878 172.594 53.1891 151.42 56.9916C130.246 60.7941 117.062 57.5161 117.062 57.5161L58.734 23.2936C58.734 23.2936 104.944 27.0961 146.626 6.90344L203.223 35.4878Z" fill="#0B6AB2"></path>
            <path d="M63.3044 316.469C61.4436 316.817 59.5827 315.542 59.2338 313.687L28.9956 145.374C28.6467 143.52 29.926 141.665 31.7868 141.317L338.24 86.604C340.1 86.2562 341.961 87.5313 342.31 89.386L372.548 257.699C372.781 259.669 371.502 261.524 369.641 261.756L63.3044 316.469Z" fill="#6B93D6"></path>
            <path fillRule="evenodd" clipRule="evenodd" d="M44.3476 123.235L40.2771 147.461L68.7708 306.269C68.7708 306.269 119.594 284.013 156.811 278.448C177.745 275.319 193.213 276.942 202.866 278.912L223.916 275.203C232.29 269.986 246.246 263.147 266.948 258.858C303.815 251.208 359.29 254.453 359.29 254.453L330.797 95.6461L318.585 74.3173L44.3476 123.235ZM182.164 122.191L188.91 121.032C186.816 122.655 185.886 123.582 185.886 123.582C185.886 123.582 184.606 123.003 182.164 122.191Z" fill="url(#paint0_linear_4852_54971)"></path>
            <path d="M44.347 123.235L72.8407 282.043C72.8407 282.043 120.64 260.25 155.88 255.15C191.119 249.934 210.308 259.555 210.308 259.555L181.815 100.747C181.815 100.747 162.509 91.1262 127.386 96.3425C92.1467 101.559 44.347 123.235 44.347 123.235Z" fill="url(#paint1_linear_4852_54971)"></path>
            <path d="M318.468 74.3176L346.962 233.125C346.962 233.125 294.51 229.3 259.736 236.603C224.846 243.905 210.192 259.554 210.192 259.554L181.698 100.747C181.815 100.747 196.469 85.098 231.243 77.7951C266.133 70.4923 318.468 74.3176 318.468 74.3176Z" fill="url(#paint2_linear_4852_54971)"></path>
            <path d="M181.815 100.747C181.815 100.747 261.365 47.3087 266.831 0.130127L295.325 158.937C289.742 206.232 210.309 259.554 210.309 259.554" fill="url(#paint3_linear_4852_54971)"></path>
            <path d="M92.1458 172.155C90.285 170.88 89.8198 168.33 91.0991 166.475L100.985 152.101C102.264 150.247 104.823 149.783 106.683 151.058C108.544 152.333 109.009 154.883 107.73 156.738L97.8446 171.112C97.0304 172.271 95.7511 172.851 94.4718 172.851C93.6577 172.851 92.8436 172.619 92.1458 172.155Z" fill="#0B6AB2"></path>
            <path d="M104.242 169.952L89.8209 160.099C87.9601 158.823 87.4949 156.273 88.7742 154.419C90.0535 152.564 92.6121 152.1 94.4729 153.375L108.894 163.228C110.755 164.503 111.22 167.054 109.941 168.908C109.127 169.952 107.848 170.647 106.568 170.647C105.754 170.647 104.94 170.415 104.242 169.952Z" fill="#0B6AB2"></path>
            <path d="M142.853 162.763C140.993 161.488 140.527 158.938 141.807 157.083L151.692 142.709C152.972 140.854 155.53 140.391 157.391 141.666C159.252 142.941 159.717 145.491 158.438 147.346L148.552 161.72C147.738 162.879 146.459 163.458 145.179 163.458C144.365 163.458 143.551 163.227 142.853 162.763Z" fill="#0B6AB2"></path>
            <path d="M155.065 160.561L140.643 150.708C138.782 149.433 138.317 146.883 139.597 145.028C140.876 143.174 143.434 142.71 145.295 143.985L159.717 153.838C161.577 155.113 162.043 157.663 160.763 159.518C159.949 160.677 158.67 161.257 157.391 161.257C156.576 161.257 155.762 161.025 155.065 160.561Z" fill="#0B6AB2"></path>
            <path d="M101.45 207.972C99.2405 207.508 97.8448 205.422 98.3101 203.219C101.334 188.266 112.964 176.906 127.967 174.124C142.97 171.342 157.972 177.833 166.113 190.7C167.276 192.555 166.811 195.105 164.834 196.264C162.973 197.424 160.415 196.96 159.252 194.989C152.855 184.904 141.225 179.92 129.479 182.007C117.732 184.209 108.661 193.135 106.335 204.726C105.87 206.813 104.241 208.088 102.264 208.088C102.032 208.088 101.683 208.088 101.45 207.972Z" fill="#0B6AB2"></path>
            <path fillRule="evenodd" clipRule="evenodd" d="M372.167 101.57C373.215 95.8271 375.062 91.2426 377.871 87.7024C380.655 84.3022 384.518 80.5201 389.408 76.6361C393.118 73.6945 395.888 71.1603 397.693 69.1737C399.523 67.047 400.681 64.6529 401.218 61.7112C401.806 58.4893 401.15 55.764 399.11 53.5099C397.07 51.2558 394.178 49.7148 390.271 49.0015C386.642 48.3392 383.165 48.4282 379.813 49.4087C376.488 50.2491 371.866 51.8663 366.166 53.8656C362.623 55.1007 359.679 55.4318 357.585 55.0497C355.213 54.6166 353.438 53.2794 352.147 50.8725C350.856 48.4656 350.479 45.7912 351.016 42.8495C351.399 40.7483 352.125 39.1437 353.078 37.8702C354.032 36.5968 355.543 35.4252 357.283 34.5847C363.023 31.5795 369.168 29.5166 376.136 28.4725C382.965 27.403 389.729 27.4796 396.288 28.6768C403.266 29.9505 409.316 32.3576 414.438 35.898C419.56 39.4384 423.272 43.5901 425.55 48.4931C427.827 53.3961 428.468 58.5791 427.47 64.0424C426.703 68.2449 425.176 71.8742 422.862 75.0706C420.548 78.267 418.057 80.8521 415.364 82.966C412.671 85.0799 409.012 87.7414 404.58 90.6958C399.537 93.8284 395.714 96.6045 393.135 98.884C390.556 101.163 388.623 103.85 387.451 107.111C386.649 109.135 385.365 110.638 383.739 111.644C382.139 112.51 380.337 112.905 378.243 112.523C375.897 111.949 374.096 110.752 373.033 108.677C371.944 106.741 371.656 104.372 372.167 101.57ZM360.043 133.229C360.81 129.027 362.845 125.779 365.983 123.602C369.146 121.284 372.89 120.52 377.077 121.284C381.124 122.023 384.218 124.035 386.497 127.346C388.638 130.632 389.394 134.389 388.652 138.451C387.885 142.654 385.85 145.901 382.713 148.078C379.55 150.396 375.806 151.16 371.758 150.421C367.572 149.657 364.478 147.645 362.198 144.334C360.058 141.048 359.302 137.291 360.043 133.229Z" fill="#F38548"></path>
            <path d="M1.4739 390.543L12.3935 238.774L29.156 239.953L18.2364 391.722C17.8786 396.341 13.9017 399.845 9.26783 399.486C4.63393 399.127 1.11613 395.161 1.4739 390.543Z" fill="#6B93D6"></path>
            <path d="M21.4031 218.145C21.833 217.225 23.0211 217.317 23.3042 218.292L29.2665 240.08L12.3943 238.773L21.4031 218.145Z" fill="#ACBAFF"></path>
            <path d="M18.2086 225.405L25.4545 225.966L23.3095 218.175C23.0174 217.318 21.7108 217.217 21.409 218.028L18.2086 225.405Z" fill="#0B6AB2"></path>
            <defs>
              <linearGradient id="paint0_linear_4852_54971" x1="40.2771" y1="74.3173" x2="40.2771" y2="306.269" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E1DEDE"></stop>
                <stop offset="1" stopColor="#EBE9E9"></stop>
              </linearGradient>
              <linearGradient id="paint1_linear_4852_54971" x1="44.347" y1="94.7924" x2="44.347" y2="282.043" gradientUnits="userSpaceOnUse">
                <stop stopColor="#EBE9E9"></stop>
                <stop offset="1" stopColor="#FAF9F9"></stop>
              </linearGradient>
              <linearGradient id="paint2_linear_4852_54971" x1="181.698" y1="73.3163" x2="181.698" y2="259.554" gradientUnits="userSpaceOnUse">
                <stop stopColor="#EBE9E9"></stop>
                <stop offset="1" stopColor="#FAF9F9"></stop>
              </linearGradient>
              <linearGradient id="paint3_linear_4852_54971" x1="181.815" y1="0.130127" x2="181.815" y2="259.554" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F3F3F3"></stop>
                <stop offset="1" stopColor="white"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
}
